<template>
  <div>
    <v-container max-height="100px">
      <panels-toolbar
        :items="data.items"
        :selectedTable="selectedTable"
        :receiptNote="receiptNote"
        :currency="currency"
        :discount="data.discount"
        @openSearchItemsDialog="openSearchItemsDialog"
        @openCloseBalanceDialog="openCloseBalanceDialog"
        @openReceiptsDialog="openReceiptsDialog"
        @finishPaymentRep="confirmReprezentacija()"
        @deliveryFinishPayment="deliveryFinish"
        @openSplitReceiptDialog="openSplitReceiptDialog"
        @addWriteInProduct="onAddWriteInProduct"
        @printRecapitulation="onPrintRecapitulation"
      ></panels-toolbar>
      <!------------------------------------------------------------------------- GOTOVINSKI -->
      <v-row v-if="cashRegisterType !== 'VP'">
        <v-col cols="4" style="padding: 0 2px 2px 0">
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  min-height="3.5rem"
                  color="#2C5A90"
                  @click="finishPayment('G', 'BO')"
                  :disabled="!buttonsEnabled || cashRegisterType === 'VP'"
                >
                  <span v-if="!smallWindow" class="white--text tile btn-lg">{{
                    $t("$vuetify.paymentMethods.cash")
                  }}</span>
                  <v-icon color="white" large v-if="smallWindow"
                    >mdi-currency-eur</v-icon
                  >
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.payWithCash") }}</span>
          </v-tooltip>
        </v-col>
        <!------------------------------------------------------------------------- KARTIČNO -->
        <v-col cols="4" style="padding: 0 2px 2px 0">
          <v-menu bottom right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip
                bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false"
              >
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn
                      style="border-radius: 12px"
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        {
                          'disable-events':
                            !buttonsEnabled || cashRegisterType === 'VP',
                        },
                        'white--text',
                        {
                          darkblue: buttonsEnabled && cashRegisterType !== 'VP',
                        },
                      ]"
                      v-bind="attrs"
                      @click="checkCards()"
                    >
                      <span
                        v-if="!smallWindow"
                        class="white--text tile btn-lg"
                        >{{ $t("$vuetify.paymentMethods.card") }}</span
                      >
                      <v-icon color="white" large v-if="smallWindow"
                        >mdi-credit-card-outline</v-icon
                      >
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payWithCards") }}</span>
              </v-tooltip>
            </template>
          </v-menu>
        </v-col>
        <!------------------------------------------------------------------------- R1 -->
        <v-col cols="4" style="padding: 0 0 2px 0">
          <v-menu bottom right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip
                bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false"
              >
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn
                      style="border-radius: 12px"
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        { 'disable-events': !buttonsEnabled },
                        'white--text',
                        { darkblue: buttonsEnabled },
                      ]"
                      v-bind="attrs"
                      @click="openR1ReceiptPaymentDialog"
                    >
                      <span class="white--text tile btn-lg">R1</span>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("$vuetify.tooltips.payR1") }}</span>
              </v-tooltip>
            </template>
          </v-menu>
        </v-col>
      </v-row>
      <!------------------------------------------------------------------------- RAČUN (SAMO VP) -->
      <v-row v-if="cashRegisterType === 'VP'">
        <v-col cols="12" style="padding: 0 0 2px 0">
          <v-menu bottom right>
            <template v-slot:activator="{ on: onMenu, attrs }">
              <v-tooltip
                bottom
                :open-delay="tooltipDelay"
                :open-on-hover="true"
                :open-on-click="false"
                :open-on-focus="false"
              >
                <template #activator="{ on: onTooltip }">
                  <div v-on="{ ...onMenu, ...onTooltip }">
                    <v-btn
                      style="border-radius: 12px"
                      block
                      tile
                      depressed
                      min-height="3.5rem"
                      icon
                      :class="[
                        { 'disable-events': !buttonsEnabled },
                        'white--text',
                        { darkblue: buttonsEnabled },
                      ]"
                      v-bind="attrs"
                      @click="openWsPaymentDialog"
                    >
                      <span class="white--text tile btn-lg">Račun</span>
                    </v-btn>
                  </div>
                </template>
                <span>Račun</span>
              </v-tooltip>
            </template>
          </v-menu>
        </v-col>
      </v-row>
      <!------------------------------------------------------------------------- END SELECTING -->
      <v-row>
        <v-col
          xl="3"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          style="padding: 0 2px 2px 0"
        >
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  min-height="3.5rem"
                  color="#2c5a90"
                  @click="onOpenTablesDialog"
                >
                  <span class="white--text tile btn-lg">{{
                    $t("$vuetify.tables.select")
                  }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.selectTable") }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          xl="3"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          style="padding: 0 2px 2px 0"
        >
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  min-height="3.5rem"
                  color="#2c5a90"
                  @click="onNewOrder"
                >
                  <span class="white--text tile btn-lg">{{
                    $t("$vuetify.tables.new")
                  }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.newOrder") }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          xl="3"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          style="padding: 0 2px 2px 0"
        >
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  style="border-radius: 12px"
                  block
                  tile
                  depressed
                  :disabled="
                    selectedTable.number === 0 || data.items.length === 0
                  "
                  min-height="3.5rem"
                  color="#2c5a90"
                  @click="onClearOrder"
                >
                  <span class="white--text tile btn-lg">{{
                    $t("$vuetify.tables.clear")
                  }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.clearTable") }}</span>
          </v-tooltip>
        </v-col>
        <v-col xl="3" lg="6" md="6" sm="12" xs="12" style="padding: 0 0 2px 0">
          <v-tooltip
            bottom
            :open-delay="tooltipDelay"
            :open-on-hover="true"
            :open-on-click="false"
            :open-on-focus="false"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  block
                  tile
                  depressed
                  :disabled="
                    selectedTable.number === 0 || data.items.length === 0
                  "
                  min-height="3.5rem"
                  style="
                    background-color: #9c27b0 !important;
                    border-radius: 12px;
                  "
                  @click="onSaveItemsToTable"
                >
                  <span
                    v-if="selectedTable.number !== 0"
                    class="white--text tile btn-lg"
                    >{{ `${$t("$vuetify.tables.saveTable")}` }}</span
                  >
                </v-btn>
              </div>
            </template>
            <span>{{ $t("$vuetify.tooltips.saveTable") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          xl="3"
          lg="4"
          md="6"
          sm="12"
          xs="12"
          v-for="(table, idxc) in selectedTables"
          :key="idxc"
          class="panel-cell"
          align="center"
        >
          <div v-if="!table.type">
            <div
              style="
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
              "
              :ref="table.id"
              :key="timerCount"
              :class="
                showWaitingTime(table.update_time, table.id, true) < 1800000
                  ? 'notification-green'
                  : showWaitingTime(table.update_time, table.id, true) < 3600000
                  ? 'notification-yellow'
                  : 'notification-red'
              "
            >
              {{ showWaitingTime(table.update_time, table.id) }}
            </div>
            <v-btn
              style="
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
              "
              v-longpress:[table]="renameTable"
              :style="
                tableLocked(table)
                  ? 'background-color: lightgrey'
                  : `background-color: ${table.color}`
              "
              block
              tile
              depressed
              min-height="3.5rem"
              :color="table.color"
              return-object
              @click="onTableCellClick(table)"
            >
              <span class="white--text tile btn-md">{{ table.name }}</span>
            </v-btn>
          </div>
          <div
            v-if="
              table.type &&
              table.type === 'tableDialogOpener' &&
              selectedTables.length > 1
            "
          >
            <div
              style="
                border-top-right-radius: 12px;
                border-top-left-radius: 12px;
                color: #9c27b0;
              "
              :ref="table.id"
              :key="timerCount"
              :class="'notification-purple'"
              st
            >
              Stolovi
            </div>
            <v-btn
              style="
                border-bottom-right-radius: 12px;
                border-bottom-left-radius: 12px;
              "
              block
              tile
              depressed
              min-height="56px"
              color="#2c5a90"
              @click="showTbls = true"
            >
              <span class="white--text tile btn-lg">Stolovi</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-dialog
        v-model="showTbls"
        v-if="showTbls"
        content-class="my-custom-dialog"
        transition="dialog-top-transition"
        persistent
        scrollable
        max-width="60%"
        @keydown.esc="showTbls = false"
      >
        <v-card
          tile
          color="white"
          class="pb-3"
          style="border-radius: 25px !important"
        >
          <v-card-title
            class="d-flex"
            style="
              vertical-align: middle;
              background-color: #1577da;

              height: 58px;
              color: white;
            "
          >
            <span class="text-h5" style="font-weight: bold">{{
              $t("$vuetify.tables.tables")
            }}</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="white pt-5 pb-5">
            <v-container class="panel-container">
              <v-row>
                <v-col
                  :cols="3"
                  v-for="(table, idxc) in selectedTables.slice(1)"
                  :key="idxc"
                  class="panel-cell"
                  align="center"
                >
                  <div
                    style="
                      border-top-right-radius: 12px;
                      border-top-left-radius: 12px;
                    "
                    :ref="table.id"
                    :key="timerCount"
                    :class="
                      showWaitingTime(table.update_time, table.id, true) <
                      1800000
                        ? 'notification-green'
                        : showWaitingTime(table.update_time, table.id, true) <
                          3600000
                        ? 'notification-yellow'
                        : 'notification-red'
                    "
                  >
                    {{ showWaitingTime(table.update_time, table.id) }}
                  </div>
                  <v-btn
                    style="
                      border-bottom-left-radius: 12px;
                      border-bottom-right-radius: 12px;
                    "
                    v-longpress:[table]="renameTable"
                    :style="
                      tableLocked(table)
                        ? 'background-color: lightgrey'
                        : `background-color: ${table.color}`
                    "
                    block
                    tile
                    depressed
                    min-height="3.5rem"
                    :color="table.color"
                    return-object
                    @click="onTableCellClick(table, true)"
                  >
                    <span class="white--text tile btn-md">{{
                      table.name
                    }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex pt-0 mt-0 pr-7 justify-end">
            <v-btn class="btn dangerButton" @click="showTbls = false">
              Odustani
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <tables-dialog
      v-if="addTableDialogOpen"
      :availableTables="availableTables"
      @addTable="onAddTable"
      @selectTable="onSelectTable"
      @saveTable="saveTable"
      @deleteTable="onDeleteTable"
      @closeTablesDialog="onCloseTablesDialog"
    ></tables-dialog>
    <account-details-dialog
      v-if="r1DialogOpen"
      :data="data"
      :paymentMethod="paymentMethod"
      :selectedTable="selectedTable"
      :selectedTables="selectedTables"
      :selectedPrinter="selectedPrinter"
      :selectedTerminal="selectedTerminal"
      :showPreview="showPreview"
      @closeR1Dialog="onR1DialogClose"
      @imidiatePrintPrepare="imidiatePrintPrepare"
      @accountDetailsFinishPayment="onAccountDetailsFinishPayment"
      @accountDetailsPreviewReceipt="onAccountDetailsPreviewReceipt"
    ></account-details-dialog>
    <split-receipt-dialog
      ref="splitReceiptDialog"
      :selectedTable="selectedTable"
      :selectedTables="selectedTables"
      :availableTables="availableTables"
      :data="data"
      @createNewTable="onCreateNewTable"
      @addItemsToTable="onAddItemsToTable"
    ></split-receipt-dialog>
    <terminals ref="terminals" @terminalSelected="terminalSelected"></terminals>
    <v-dialog v-model="tableRename" width="25%">
      <v-card>
        <v-card-title> Preimenuj stol</v-card-title>
        <v-card-text>
          <v-text-field v-model="newTableName"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="border-radius: 12px"
            color="primary"
            variant="text"
            @click="updateTableName()"
          >
            Spremi
          </v-btn>

          <v-btn
            style="border-radius: 12px"
            color="primary"
            variant="text"
            @click="tableRename = false"
          >
            Otkaži
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <card-payment-selection-dialog
      ref="cardSelectionDialog"
      :cards="availableCards"
      @checkTerminal="checkTerminal"
    ></card-payment-selection-dialog>
    <r1-receipt-payment-dialog
      ref="r1ReceiptPaymentDialog"
      :paymentMenu="paymentMenu"
      @menuPaymentClick="menuPaymentClick"
      @checkCardsForR="checkCardsForR"
    ></r1-receipt-payment-dialog>
    <ws-payment-selection-dialog
      ref="wsPaymentSelectionDialog"
      :paymentMenu="paymentMenu"
      @menuPaymentClick="menuPaymentClick"
      @checkCards="checkCards"
    ></ws-payment-selection-dialog>
  </div>
</template>
<script>

import rules from '@/plugins/rules'
import state from '@/state'
import { auth, df } from '@/plugins/firebase'
import { toISOLocal, clone } from '@/plugins/utils'
import AccountDetailsDialog from '@/modules/cash-register/components/dialogs/AccountDetailsDialog'
import PanelsToolbar from '@/modules/cash-register/components/PanelsToolbar'
import TablesDialog from '@/modules/cash-register/components/dialogs/TablesDialog.vue'
import SplitReceiptDialog from '@/modules/cash-register/components/dialogs/SplitReceiptDialog'
import { printOrder } from '@/libs/bixolon/order'
import Terminals from '@/modules/cash-register/components/dialogs/Terminals.vue'
import Table from '@/model/Table'
import WriteInProductModel from '@/model/WriteInProduct'
import CardPaymentSelectionDialog from '@/modules/cash-register/components/dialogs/CardPaymentSelectionDialog'
import R1ReceiptPaymentDialog from '@/modules/cash-register/components/dialogs/R1ReceiptPaymentDialog'
import WsPaymentSelectionDialog from '@/modules/cash-register/components/dialogs/WsPaymentSelectionDialog'

import { remapItems } from '@/libs/receiptIssuing/items'
import { getPayload, getVirmanPayload } from '@/libs/receiptIssuing/payload'
import { getPaymentMethod } from '@/libs/receiptIssuing/commons'
import { sendHttp, sendVirmanHttp, sendPreview } from '@/libs/receiptIssuing/payment'

import { v4 as uuidv4 } from 'uuid'
import EventBus from '@/plugins/event-bus'
import { retry } from '@/libs/receiptIssuing/validation'
import { doPrintCartRecapitulation } from '@/libs/bixolon/reprint.js'
import LogData from '@/model/LogData'
import log from '@/mixins/log'

export default {
  components: {
    AccountDetailsDialog,
    TablesDialog,
    PanelsToolbar,
    SplitReceiptDialog,
    Terminals,
    CardPaymentSelectionDialog,
    R1ReceiptPaymentDialog,
    WsPaymentSelectionDialog
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    selectedPrinter: {
      // Passed over from PanelRows or PanelRowsWide
      type: Object,
      default: () => {}
    }
  },
  inject: ['showMsgBox', 'confirm', 'confirmClose', 'showLoader', 'hideLoader', 'openPdfPrint'],
  mixins: [log],
  data: function () {
    return {
      tableRename: false,
      timerCount: 0,
      user: undefined,
      tableToRename: undefined,
      initialTableName: undefined,
      manager: false,
      userId: undefined,
      r1DialogOpen: false,
      addTableDialogOpen: false,
      note: '',
      languages: [],
      language: undefined,
      receiptNumber: 1,
      bill_sequence: 0,
      offer_sequence: 0,
      last_receipt: '',
      receiptType: '',
      receiptTypes: [],
      defaultReceiptTypes: [],
      multiplier: 0,
      // fiscalize: false,
      issueDate: '',
      minIssueDate: '',
      maxIssueDate: '',
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        gt: rules.gt,
        arrReq: rules.arrReq()
      },
      listeners: [],
      type: '',
      cashRegisterForTerminal: undefined,
      paymentMethod: '',
      keypadNumber: '',
      editMode: false,
      ordersView: false,
      selectedTable: {},
      selectedTables: [],
      availableTables: [],
      destinationTable: {},
      initialTable: {},
      printers: [],
      orgUnits: [],
      paymentMenu: [
        { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
        { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
        { text: this.$t('$vuetify.paymentMethods.transaction'), type: 'T', icon: 'mdi-card' },
        { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' }
      ],
      availableCards: [],
      selectedCard: undefined,
      selectedTerminal: undefined,
      tableNameSuffix: 1,
      receiptNote: null,
      tableForRename: undefined,
      newTableName: '',
      tooltipDelay: 500,
      showPreview: false,
      previewReceiptType: 'R',
      currency: 'EUR',
      smallWindow: false,
      showTbls: false
    }
  },
  computed: {

    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    },
    buttonsEnabled () {
      return this.data.items.length > 0
    },
    cashRegisterType () {
      return state.getCashRegister().type
    }
  },

  watch: {
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
    },
    editMode: {
      handler (nv, ov) {
      }
    },
    cashRegisterType: {
      handler (type) {
        if (type === 'VP') {
          this.paymentMenu = [
            { text: this.$t('$vuetify.paymentMethods.transaction'), type: 'T', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.offer'), type: 'P', icon: 'mdi-card' }
          ]
        } else if (type === 'ALL') {
          this.paymentMenu = [
            { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
            { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.transaction'), type: 'T', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.offer'), type: 'P', icon: 'mdi-card' }
          ]
        } else {
          this.paymentMenu = [
            { text: this.$t('$vuetify.paymentMethods.cash'), type: 'G', icon: 'mdi-cash' },
            { text: this.$t('$vuetify.paymentMethods.card'), type: 'K', icon: 'mdi-card' },
            { text: this.$t('$vuetify.paymentMethods.virman'), type: 'V', icon: 'mdi-card' }
          ]
        }
      },
      immediate: true
    },

    selectedTables: {
      handler (selectedTables) {
        clearInterval(this._updateTimer)
        if (selectedTables.length) {
          this._updateTimer = setInterval(() => this.timerCount++, 1000)
        }
      },
      immediate: true
    },
    beforeUnmount () {
      clearInterval(this._updateTimer)
    },
    // Vue 2.x equivalent of beforeUnmount
    beforeDestroy () {
      clearInterval(this._updateTimer)
    }

  },
  methods: {
    onAddWriteInProduct (item) {
      this.$emit('addWriteInProduct', item)
    },

    onPrintRecapitulation () {
      doPrintCartRecapitulation(this.data, this.selectedPrinter)
      // this.printCartRecapitulation(this.data, this.selectedPrinter)
    },

    renameTable (table) {
      this.tableForRename = table
      this.newTableName = table.name
      this.tableRename = true
    },
    updateTableName () {
      var tb = this.tableForRename
      var newName = this.newTableName

      this.tableRename = false
      this.tableForRename = undefined
      this.newTableName = ''

      if (this.selectedTable.id === tb.id) {
        this.selectedTable.name = newName
      }

      if (this.selectedTables && this.selectedTables.length > 0) {
        this.selectedTables.forEach((tbl, key) => {
          if (tbl.id === tb.id) {
            this.selectedTables[key].name = newName
          }
        })
      }

      this.updateTableNameInDb(tb.id, newName)
      this.$forceUpdate()
    },
    updateTableNameInDb (tableId, newName) {
      df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tableId}`).update({
        name: newName
      })
        .then(() => {
          console.info('Table succesfully updated.')
        })
        .catch(() => {
          console.error('Table has not been updated succesfully.')
        })
    },
    // Provjera i ponasanje ako nema aktivnih kartica
    checkCardsForR () {
      if (!this.availableCards || this.availableCards.length === 0) {
        if (this.cashRegisterForTerminal.terminals) {
          var terms = this.cashRegisterForTerminal.terminals
          var termsArray = []
          if (terms && Object.keys(terms).length > 0) {
            Object.keys(terms).forEach(key => {
              termsArray.push(terms[key])
            })

            // Ako postoji vise terminala, dopusti odabir bilo kojeg
            if (termsArray && termsArray.length > 1) {
              this.$refs.terminals.open(this.cashRegisterForTerminal, undefined, 'K', 'R')
            } else if (termsArray && termsArray.length === 1) {
              // Ako postoji ima samo jedan terminal, salji na njega
              this.selectedTerminal = termsArray[0]
              this.insertAccountDetails('K', 'R', undefined, undefined)
            } else {
              // Ako nema ni jedan terminal, samo napravi racun
              this.insertAccountDetails('K', 'R', undefined, undefined)
            }
          }
        } else {
          // Ako nema ni jedan terminal, samo napravi racun
          this.insertAccountDetails('K', 'R', undefined, undefined)
        }
      } else {
        this.openCardSelectionDialog('R')
      }
    },

    openCardSelectionDialog (type) {
      this.$refs.cardSelectionDialog.open(type)
    },
    openR1ReceiptPaymentDialog (mode) {
      this.$refs.r1ReceiptPaymentDialog.open(mode)
    },
    openWsPaymentDialog () {
      this.$refs.wsPaymentSelectionDialog.open()
    },

    // Provjera i ponasanje ako nema aktivnih kartica
    checkCards () {
      if (!this.availableCards || this.availableCards.length === 0) {
        // Nema aktivnih kartica
        if (this.cashRegisterForTerminal.terminals) {
          var terms = this.cashRegisterForTerminal.terminals
          var termsArray = []
          if (terms && Object.keys(terms).length > 0) {
            Object.keys(terms).forEach(key => {
              termsArray.push(terms[key])
            })

            // Ako postoji vise terminala, dopusti odabir bilo kojeg
            if (termsArray && termsArray.length > 1) {
              this.$refs.terminals.open(this.cashRegisterForTerminal, undefined, 'K', 'BO')
            } else if (termsArray && termsArray.length === 1) {
              // Ako postoji ima samo jedan terminal, salji na njega
              this.finishPayment('K', 'BO', undefined, undefined, termsArray[0])
            } else {
              // Ako nema ni jedan terminal, samo napravi racun
              this.finishPayment('K', 'BO', undefined, undefined)
            }
          }
        } else {
          // Ako nema ni jedan terminal, samo napravi racun
          this.finishPayment('K', 'BO', undefined, undefined)
        }
      } else {
        // Ima aktivnih kartica, pokaži dialog za odabir
        this.openCardSelectionDialog('BO')
      }
    },
    terminalSelected (terminal, paymentCard, method, receiptType) {
      this.selectedTerminal = terminal

      if (receiptType === 'R') {
        this.insertAccountDetails('K', 'R', undefined, paymentCard)
      } else {
        this.finishPayment(method, receiptType, undefined, paymentCard, terminal)
      }
    },
    checkTerminal (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      var termsWithCards = []
      if (this.cashRegisterForTerminal.terminals) {
        var terms = this.cashRegisterForTerminal.terminals

        Object.keys(terms).forEach(key => {
          if (terms[key].cards && terms[key].cards.length === 0) {
            termsWithCards.push(terms[key])
          }

          if (terms[key].cards && terms[key].cards.length > 0 && terms[key].cards.some(card => card.ID === paymentCard.id)) {
            termsWithCards.push(terms[key])
          }
        })
      }

      if (termsWithCards) {
        switch (termsWithCards.length) {
          case 0:
            if (receiptType === 'R') {
              this.insertAccountDetails('K', 'R', undefined, paymentCard)
            } else {
              this.finishPayment(method, receiptType, undefined, paymentCard)
            }
            break
          case 1:
            var terminal = termsWithCards[0]
            if (receiptType === 'R') {
              this.selectedTerminal = terminal
              this.insertAccountDetails('K', 'R', undefined, paymentCard)
            } else {
              this.finishPayment(method, receiptType, undefined, paymentCard, terminal)
            }
            break
          default:
            this.$refs.terminals.open(this.cashRegisterForTerminal, paymentCard, method, receiptType)
            break
        }
      }
    },

    showWaitingTime (updateDate, tableId, mode = false) {
      const diff = new Date() - new Date(updateDate)
      const twoD = x => `${Math.floor(x)}`.padStart(2, '0')
      const HH = twoD((diff / (60 * 60 * 1000)) % 24)
      const mm = twoD((diff / (60 * 1000)) % 60)
      const ss = twoD((diff / 1000) % 60)
      if (mode) return diff
      else { return `${HH}:${mm}:${ss}` }
    },
    openSplitReceiptDialog () {
      this.$refs.splitReceiptDialog.open(this.selectedTable)
    },
    // From split receipt
    async onCreateNewTable (sourceTable, destinationTable) {
      this.selectedTable = clone(sourceTable)
      this.destinationTable = clone(destinationTable)

      await this.updateTable(this.selectedTable)
      await this.addSplitTable(this.destinationTable, this.selectedTable)

      this.$emit('createNewTable', this.selectedTable, destinationTable)
    },
    async onAddItemsToTable (sourceTable, targetTable) {
      const srcTbl = this.selectedTables.find(t => t.id === sourceTable.id)
      const tgtTbl = this.selectedTables.find(t => t.id === targetTable.id)

      srcTbl.items = []
      tgtTbl.items = []

      sourceTable.items.forEach(item => {
        srcTbl.items.push(item)
      })
      targetTable.items.forEach(item => {
        tgtTbl.items.push(item)
      })

      await this.updateTable(srcTbl)
      await this.updateTable(tgtTbl)

      this.onNewOrder()
    },
    confirmReprezentacija () {
      this.confirm({
        title: 'Reprezentacija',
        message: 'Ovaj račun će biti reprezentacija, jeste li sigurni da želite nastaviti?',
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then((resp) => {
        if (resp) {
          this.confirmClose()
          this.finishPayment('O', 'REPREZENTACIJA')
        }
      })
    },
    deliveryFinish (delivery) {
      this.finishPayment('O', 'DELIVERY', delivery.value)
    },
    menuPaymentClick (menu) {
      switch (menu.type) {
        case 'G':
          this.insertAccountDetails('G', 'R')
          break
        case 'K':
          this.insertAccountDetails('K', 'R')
          break
        case 'V':
          this.insertAccountDetails('V', 'R')
          break
        case 'T':
          this.insertAccountDetails('T', 'R')
          break
        case 'P':
          this.insertAccountDetails('P', 'R')
      }
    },
    tableLocked (table) {
      return !this.editMode && table.locked && table.locked_by !== state.getUser().id
    },
    switchLocks (newSelectedTable, oldSelectedTable) {
      this.lock(newSelectedTable)

      if (oldSelectedTable.id) {
        this.unlock(oldSelectedTable)
      } else {
        this.selectedTable = newSelectedTable
      }
    },
    async lock (newSelectedTable) {
      const me = this

      const newUpdateArgs = { locked: true, color: '#c02dd8', locked_by: state.getUser().id }
      df.doc(`location_orders/${state.getPointOfSale().id}/tables/${newSelectedTable.id}`).update(newUpdateArgs)
        .then(() => {
        }).catch((err) => {
          console.error(err)
          me.showMsgBox({ text: 'Pogreška prilikom zaključavanja', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
      this.selectedTable = newSelectedTable

      this.$emit('selectTable', newSelectedTable)
    },
    async unlock (oldSelectedTable) {
      const me = this

      const oldUpdateArgs = { locked: false, color: 'primary' }
      df.doc(`location_orders/${state.getPointOfSale().id}/tables/${oldSelectedTable.id}`).update(oldUpdateArgs)
        .then(() => {
        })
        .catch((err) => {
          console.error(err)
          me.showMsgBox({ text: 'Pogreška prilikom zaključavanja', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },
    onOpenTablesDialog () {
      if (!this.orderHasChanged()) {
        this.addTableDialogOpen = true
      } else {
        this.confirm({
          title: this.$t('$vuetify.tables.note'),
          message: this.$t('$vuetify.tables.noteMessage'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.tables.continue'),
            cancelText: this.$t('$vuetify.tables.cancel')
          }
        }).then(async (resp) => {
          if (resp) {
            this.addTableDialogOpen = true
          }
          this.confirmClose()
        })
      }
    },
    onCloseTablesDialog () {
      this.addTableDialogOpen = false
    },
    async onAddTable (table) {
      if (this.selectedTable.id) {
        const oldTb = clone(this.selectedTable)
        this.unlock(oldTb)
        this.selectedTable = table
        this.initialTable = clone(this.selectedTable)
        this.clearOrderFromScreen()
        this.$emit('selectTable', table)
        this.$emit('recalculate')
      } else {
        // Stol nije odabran
        if (this.selectedTable.number !== 0) {
          const oldTb = clone(table)
          const newTb = clone(this.selectedTable)
          this.switchLocks(oldTb, newTb)
          this.initialTable = clone(newTb)
        } else {
          this.selectedTable = table
          var initTable = clone(table)
          delete initTable.items
          this.initialTable = clone(initTable)

          this.data.items.forEach(item => {
            this.selectedTable.items.push(item)
          })
          this.$emit('selectTable', table)
        }
        this.onSaveItemsToTable()
      }

      // if (this.data.items.length > 0) {
      //   this.confirm({
      //     title: this.$t('$vuetify.tables.save_title'),
      //     message: `${this.$t('$vuetify.tables.save')} ${table.name}?`,
      //     options: {
      //       toolbar: true,
      //       width: 410,
      //       confirmText: this.$t('$vuetify.yes'),
      //       cancelText: this.$t('$vuetify.no')
      //     }
      //   }).then((resp) => {
      //     if (this.selectedTable.number !== 0) {
      //       const oldTb = clone(table)
      //       const newTb = clone(this.selectedTable)
      //       this.switchLocks(oldTb, newTb)
      //       this.initialTable = clone(newTb)
      //     } else {
      //       this.selectedTable = table
      //       this.initialTable = clone(this.selectedTable)
      //     }
      //     if (resp) {
      //       this.confirmClose()
      //       this.onSaveItemsToTable()
      //     } else {
      //       this.clearOrderFromScreen()
      //       this.$emit('recalculate')
      //     }
      //   })
      // } else {
      //   this.onSelectTable(table)
      // }
    },
    onSelectTable (table) {
      if (this.data.items.length > 0) {
        this.selectedTable.items = [...this.data.items]
      }
      if (table.discount) {
        this.data.discount = table.discount
      }
      if (table.number === this.selectedTable.number) {
        this.selectedTable = table
        this.data.items = [...this.selectedTable.items]

        this.initialTable = clone(table)

        this.$emit('selectTable', table)
        this.$emit('recalculate')
      } else {
        const oldTb = clone(table)
        const newTb = clone(this.selectedTable)
        this.switchLocks(oldTb, newTb)
        this.initialTable = clone(table)
        this.$emit('selectTable', table)
        this.$emit('recalculate')
      }
    },
    prepareCloseBalanceLogCall () {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Ispis prometa'
      req.action_id = '9'
      req.user_id = userId

      this.setLog(req)
    },
    openCloseBalanceDialog () {
      if (state.getCurrentCompany().save_log) {
        this.prepareCloseBalanceLogCall()
      }
      this.$emit('openCloseBalanceDialog')
    },
    openSearchItemsDialog () {
      this.$emit('openSearchItemsDialog')
    },
    openReceiptsDialog () {
      this.$emit('openReceiptsDialog')
    },

    getOrder () {
      const orderId = uuidv4()
      const orderItems = []

      this.selectedTable.items.forEach((item, idx) => {
        let found = false
        if (this.selectedTable.items[idx].notes) {
          if (this.selectedTable.items[idx].notes[this.selectedTable.items[idx].notes.length - 1].currentNote) {
            this.selectedTable.items[idx].notes[this.selectedTable.items[idx].notes.length - 1].orderId = orderId
            this.selectedTable.items[idx].notes[this.selectedTable.items[idx].notes.length - 1].currentNote = false
          }
        }

        if (this.initialTable && this.initialTable.items) {
          this.initialTable.items.forEach(initItem => {
            if (item.id === initItem.id) {
              found = true
              if (item.quantity !== initItem.quantity) {
                const diff = item.quantity - initItem.quantity
                const itemClone = clone(item)
                itemClone.quantity = diff

                if (item.newKeys && initItem.newKeys) {
                  const oldKeys = []
                  Object.keys(item.newKeys).forEach(nKey => {
                    Object.keys(initItem.newKeys).forEach(iKey => {
                      if (nKey === iKey) {
                        oldKeys.push(nKey)
                      }
                    })
                  })

                  if (oldKeys && oldKeys.length > 0) {
                    oldKeys.forEach(key => {
                      delete itemClone.choosenProductOrder[key]
                      delete itemClone.choosenTagOrder[key]
                    })
                  }
                }
                orderItems.push(itemClone)
              }
            }
          })
        }

        if (!found) {
          const itemClone = clone(item)
          orderItems.push(itemClone)
        }
      })

      const order = {
        id: orderId,
        order_number: this.selectedTable.orders && this.selectedTable.orders.length > 0 ? this.selectedTable.orders.length + 1 : 1,
        added_by_name: state.getCashRegisterUser() && state.getCashRegisterUser().name ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname,
        added_by_id: state.getCashRegisterUser() && state.getCashRegisterUser().id ? state.getCashRegisterUser().id : state.getUser().id,
        order_status: 'ORDERED',
        order_items: orderItems
      }

      return order
    },
    clearOrderFromScreen () {
      this.data.items = []
      this.receiptNote = null
    },

    prepareLogCall (table, order) {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Dodavanje na stol'
      req.action_id = '4'
      req.user_id = userId

      var description = table.name
      description += '\n'

      var totalOrder = 0
      if (order.order_items.length > 0) {
        order.order_items.forEach((item, k) => {
          if (k > 0 && k < order.order_items.length) {
            description += '\n'
          }

          var price = item.unitPrice * item.quantity
          if (item.discountedUnitPrice > 0) {
            price = item.discountedUnitPrice * item.quantity
          }

          if (item.quantity > 0) {
            totalOrder += price
          }

          var formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          })

          var priceFormatted = formatter.format(price / 100)
          var amt = item.quantity
          description += amt.toFixed(2).replace('.', ',') + ' x ' + item.name + ' ' + ' ' + priceFormatted
        })

        description += '\n'
      }

      var totalFormatted = (totalOrder / 100).toFixed(2).replace('.', ',')
      description += 'Ukupno narudžba: ' + totalFormatted + '€'
      req.description = description
      req.user_id = userId

      this.setLog(req)
    },
    async onSaveItemsToTable () {
      if (this.data.items.length === 0) return
      this.showLoader('Spremam ' + this.selectedTable.name, { additionalTextTimer: 3500, additionalText: 'Oprostite što mi treba malo dulje, vaš internet se usporio.' })
      this.selectedTable.items = [...this.data.items]
      this.selectedTable.locked = true
      this.selectedTable.color = '#c02dd8'
      this.selectedTable.locked_by = state.getUser().id

      const order = this.getOrder()

      if (state.getCurrentCompany().save_log) {
        this.prepareLogCall(this.selectedTable, order)
      }

      this.$emit('sendNotes', null, order)

      if (this.selectedTable.orders && this.selectedTable.orders.length > 0) {
        this.selectedTable.orders.push(order)
      } else {
        this.selectedTable.orders = [order]
      }

      this.saveItemsToDatabase(this.selectedTable, this.selectedTable.persistent)
      this.clearOrderFromScreen()
      if (!this.selectedTables.some(tbl => tbl.id === this.selectedTable.id)) {
        this.selectedTables.push(this.selectedTable)
      }

      // Dohvati prvi put
      let lastOrder = await this.getLastOrderFromDb(this.selectedTable.id, order.id)

      // Ako nema, dohvacaj 10 puta dok ne dobijes
      if (!lastOrder) {
        for (let i = 1; i <= 10; i++) {
          lastOrder = await this.getOrderWithTimeout(this.selectedTable.id, order.id)
          if (lastOrder !== undefined) break
        }
      }

      // Ako si uspio dohvatiti isprintaj sve i ocisti
      if (lastOrder) {
        this.prepareOrderForPrint(lastOrder, this.selectedTable)
        this.initialTable = clone(this.selectedTable)

        this.$emit('recalculate')
        this.onNewOrder()
      }

      if (!lastOrder) {
        this.confirm({
          title: 'Pogreška prilikom spremanja',
          message: 'Spremanje narudžbe nije uspjelo. Molimo ponovite.',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'Nastavi'
          }
        }).then((resp) => {
        })
      }

      this.$emit('resetNotes')
    },
    getOrderWithTimeout (tableId, orderId) {
      setTimeout(() => {
        return this.getLastOrderFromDb(tableId, orderId)
      }, 500)
    },
    async getLastOrderFromDb (tableId, orderId) {
      let dbOrder

      await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tableId}`)
        .get()
        .then((tableData) => {
          const tb = tableData.data()
          if (tb && tb.orders && tb.orders.length > 0) {
            tb.orders.forEach((or, index) => {
              if (or.id === orderId) {
                dbOrder = tb.orders[index]
              }
            })
          }
        })
      return dbOrder
    },
    async prepareOrderForPrint (order, table) {
      const itemsInOrgUnits = {}
      if (order && order.order_items && order.order_items.length > 0) {
        order.order_items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }

          if (item.choosenProductOrder) {
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems) {
                    product.productItems.forEach(it => {
                      if (it.org_units && it.org_units[state.getPointOfSale().id]) {
                        if (item.choosenTagOrder[key]) {
                          it.tags = item.choosenTagOrder[key]
                        }
                        it.menuId = key
                        it.menuData = {
                          productName: product.productName,
                          id: item.id,
                          name: item.name
                        }

                        if (!itemsInOrgUnits[it.org_units[state.getPointOfSale().id]]) {
                          itemsInOrgUnits[it.org_units[state.getPointOfSale().id]] = []
                        }

                        itemsInOrgUnits[it.org_units[state.getPointOfSale().id]].push(it)
                      }
                    })
                  }
                })
              }
            })
          }
        })

        var otherUnits = []
        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              otherUnits.push(orgUnit)
            }
          })
        })

        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              this.printers.forEach(printer => {
                // TODO maknuti ovu provjeru sa name, to je izmjena u id
                if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                  this.printAndSaveItems(itemsInOrgUnits[unitId], printer, orgUnit, table, order, otherUnits)
                }
              })
            }
          })
        })
      }
    },
    async imidiatePrintPrepare (items) {
      const itemsInOrgUnits = {}
      if (items && items.length > 0) {
        items.forEach(item => {
          if (item.organizational_unit_id && item.organizational_unit_id !== '') {
            if (!itemsInOrgUnits[item.organizational_unit_id]) {
              itemsInOrgUnits[item.organizational_unit_id] = []
            }
            itemsInOrgUnits[item.organizational_unit_id].push(item)
          }

          if (item.choosenProductOrder) {
            Object.keys(item.choosenProductOrder).forEach(key => {
              if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
                item.choosenProductOrder[key].forEach(product => {
                  if (product && product.productItems) {
                    product.productItems.forEach(it => {
                      if (it.org_units && it.org_units[state.getPointOfSale().id]) {
                        if (item.choosenTagOrder[key]) {
                          it.tags = item.choosenTagOrder[key]
                        }
                        it.menuId = key
                        it.menuData = {
                          id: item.id,
                          name: item.name
                        }

                        if (!itemsInOrgUnits[it.org_units[state.getPointOfSale().id]]) {
                          itemsInOrgUnits[it.org_units[state.getPointOfSale().id]] = []
                        }

                        itemsInOrgUnits[it.org_units[state.getPointOfSale().id]].push(it)
                      }
                    })
                  }
                })
              }
            })
          }
        })

        var otherUnits = []
        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              otherUnits.push(orgUnit)
            }
          })
        })

        Object.keys(itemsInOrgUnits).forEach(unitId => {
          this.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === unitId) {
              this.printers.forEach(printer => {
                // TODO maknuti ovu provjeru sa name, to je izmjena u id
                if (printer.id === orgUnit.printer || printer.name === orgUnit.printer) {
                  this.imidiatePrint(itemsInOrgUnits[unitId], printer, orgUnit, otherUnits)
                }
              })
            }
          })
        })
      }
    },
    async printAndSaveItems (items, printer, orgUnit, table, order, otherUnits) {
      const orgUnitOrderId = uuidv4()
      const docRef = df.doc(`location_units/${state.getPointOfSale().id}/units/${orgUnit.id}/orders/${orgUnitOrderId}`)

      let note = null
      if (order.notes) {
        note = order.notes.find(n => n.unitId === orgUnit.id)
      }

      try {
        await docRef.set(
          {
            id: orgUnitOrderId,
            name: table && table.name ? table.name : 'Za van',
            number: order.order_number,
            order_added_by: state.getCashRegisterUser() ? state.getCashRegisterUser().name + ' ' + state.getCashRegisterUser().surname : state.getUser().name + ' ' + state.getUser().surname,
            items: items,
            printer_address: printer.ip_address
          }
        )
      } catch (err) {
        console.error('Error saving order: ', err)
      }

      state.setPosPrinterSettings(2)
      printOrder(items, printer, table, order, orgUnit, otherUnits, note, this.hideLoader)
    },
    async imidiatePrint (items, printer, orgUnit, otherUnits) {
      state.setPosPrinterSettings(2)
      printOrder(items, printer, undefined, undefined, orgUnit, otherUnits, undefined, this.hideLoader)
    },
    saveItemsToDatabase (table, persist) {
      this.saveTable(table, persist)
    },
    orderHasChanged () {
      if (this.selectedTable.id && this.data.items && this.data.items.length > 0) {
        let itemCountDifferent = false
        const lengthDifferent = this.selectedTable.items.length !== this.data.items.length
        for (let i = 0; i < this.selectedTable.items.length; i++) {
          const tableItem = this.selectedTable.items[i]
          const cartItem = this.data.items.find(i => i.id === tableItem.id)
          if (tableItem && cartItem && tableItem.quantity !== cartItem.quantity) {
            itemCountDifferent = true
            break
          }
        }
        return itemCountDifferent || lengthDifferent
      }
    },
    onTableCellClick (table, closeDialog = false) {
      if (closeDialog) {
        this.showTbls = false
      }
      if ((!this.selectedTable.id && this.data.items && this.data.items.length > 0) || this.orderHasChanged()) {
        this.confirm({
          title: this.$t('$vuetify.tables.note'),
          message: this.$t('$vuetify.tables.noteMessage'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.tables.continue'),
            cancelText: this.$t('$vuetify.tables.cancel')
          }
        }).then(async (resp) => {
          if (resp) {
            this.receiptNote = { note: table.receiptNote?.note ? table.receiptNote?.note : '' }
            this.selectTable(table)
          }
          this.confirmClose()
        })
      } else {
        if (table.locked && table.locked_by !== state.getUser().id) {
          this.confirm({
            title: this.$t('$vuetify.tables.unlockTitle'),
            message: `${table.name} ${this.$t('$vuetify.tables.unlockMessageOther')}`,
            options: {
              toolbar: true,
              width: 410,
              confirmText: this.$t('$vuetify.yes'),
              cancelText: this.$t('$vuetify.no')
            }
          }).then(async (resp) => {
            if (resp) {
              if (this.selectedTable.number > 0) {
                df.doc(`location_orders/${state.getPointOfSale().id}/tables/${this.selectedTable.id}`).update({ locked: false, color: 'primary', locked_by: state.getUser().id })
              }

              this.selectedTable = table
              if (table.receiptNote && table.receiptNote.note !== '') {
                this.receiptNote = { note: table.receiptNote.note }
              }
              this.$emit('clearInvoice', table)
              df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`).update({ locked: true, color: '#c02dd8', locked_by: state.getUser().id })
                .catch((err) => {
                  console.error(err)
                  this.showMsgBox({ text: `Pogreška prilikom otključavanja: ${err}`, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                })
            }
            this.confirmClose()
          })
        } else if (!this.editMode) {
          this.receiptNote = { note: table.receiptNote?.note ? table.receiptNote?.note : '' }
          this.selectTable(table)
        }
      }
    },

    selectTable (table) {
      const tb = clone(table)
      this.onSelectTable(tb)
      this.$emit('recalculate')
    },
    async onNewOrder () {
      this.receiptNote = null
      if (this.selectedTable.id && !this.orderHasChanged()) {
        await this.createNewOrder(true)
      }

      if ((!this.selectedTable.id && this.data.items && this.data.items.length > 0) || this.orderHasChanged()) {
        this.confirm({
          title: this.$t('$vuetify.tables.note'),
          message: this.$t('$vuetify.tables.noteMessage'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.tables.continue'),
            cancelText: this.$t('$vuetify.tables.cancel')
          }
        }).then(async (resp) => {
          if (resp) {
            await this.createNewOrder(false)
          }
          this.confirmClose()
        })
      }

      this.hideLoader()
    },
    async createNewOrder (tableSelected) {
      if (tableSelected) {
        await df.doc(`location_orders/${state.getPointOfSale().id}/tables/${this.selectedTable.id}`).update({ locked: false, color: 'primary' })
      }
      this.selectedTable = new Table()
      this.clearOrderFromScreen()
      this.$emit('selectTable', this.selectedTable)
      this.$emit('recalculate')
    },
    onClearOrder () {
      this.confirm({
        title: 'Ukloni narudžbu',
        message: `${this.$t('$vuetify.tables.clearOrder')} ${this.selectedTable.number}?</br></br> ${this.$t('$vuetify.tables.note')} </br> Svako brisanje narudžbe će biti zabilježeno.`,
        options: {
          toolbar: true,
          width: 410,
          confirmText: this.$t('$vuetify.yes'),
          cancelText: this.$t('$vuetify.no')
        }
      }).then(async (resp) => {
        if (resp) {
          this.confirmClose()
          this.removeOrderFromTable()
        }
      })
    },
    async removeOrderFromTable () {
      // this.selectedTable.items = []
      this.initialTable.items = []
      const tblIndex = this.selectedTables.findIndex((tbl) => tbl.number === this.selectedTable.number)
      if (tblIndex > -1) {
        var tbl = clone(this.selectedTable)
        this.selectedTable.items = []
        this.selectedTable.locked = false
        this.selectedTable.locked_by = ''
        this.selectedTables.splice(tblIndex, 1)
        this.selectedTable.orders = []
        await this.emptyTable(tbl)
      }
      if (!this.selectedTable.persistent) {
        await this.deleteTable(this.selectedTable)
      }
      this.clearOrderFromScreen()
      this.data.total = 0
      this.data.fullTotal = 0
      this.data.totalWithDiscount = 0
      this.data.totalShoppingCart = 0
      this.data.discount = undefined
      this.selectedTable = new Table()
      this.$emit('clearSelection')
      this.$emit('recalculate')
      this.hideLoader()
    },
    onR1DialogClose () {
      this.r1DialogOpen = false
    },

    getTables () {
      const query = df.collection(`location_orders/${state.getPointOfSale().id}/tables`).orderBy('number')
      const listener = query.onSnapshot((doc) => {
        const tmpSelectedTables = [...this.selectedTables]

        this.selectedTables.unshift({ name: 'gumb', type: 'tableDialogOpener', action: 'openTables' })

        doc.docChanges().forEach((change) => {
          const table = change.doc.data()
          if (change.type === 'added') {
            if (table && table.items && table.items.length > 0 && ((this.manager) || (table.user_id && table.user_id === this.userId))) {
              this.selectedTables.push(table)
            }
          } else if (change.type === 'modified') {
            this.selectedTables = []
            this.selectedTables.unshift({ name: 'gumb', type: 'tableDialogOpener', action: 'openTables' })

            if (table.locked_by !== state.getUser().id) {
              this.$emit('clearInvoice', new Table())
            }
            doc.docs.forEach((rec) => {
              let modTbl = rec.data()
              if (rec.data().id === table.id) {
                modTbl = table
              }
              if (modTbl && modTbl.items && modTbl.items.length > 0 && ((this.manager) || (modTbl.user_id && modTbl.user_id === this.userId))) {
                this.selectedTables.push(modTbl)
              }
            })
          }
        })

        if (this.selectedTables.length === 0) {
          this.selectedTables = [...tmpSelectedTables]
        }

        this.availableTables = []
        doc.docs.forEach((rec) => {
          const table = rec.data()
          const availableIndex = this.availableTables.findIndex((tbl) => tbl.id === table.id)
          if (availableIndex > -1) {
            this.availableTables.splice(availableIndex, 1, table)
          } else {
            this.availableTables.push(table)
          }
        })
      })

      this.listeners.push(listener)
    },

    async updateTable (table) {
      const tbl = this.selectedTables.find(t => t.id === table.id)
      if (tbl) {
        var receiptNote = clone(this.receiptNote)
        this.receiptNote = null

        df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tbl.id}`).update({
          items: table.items,
          user_id: this.userId,
          user_name: this.user.name + ' ' + this.user.surname,
          update_time: Date.now(),
          receiptNote: receiptNote || ''
        })
          .then(() => {
            console.info('Table succesfully updated.')
          })
          .catch(() => {
            console.error('Table has not been updated succesfully.')
          })
      }
    },
    async addSplitTable (table, sourceTable) {
      const numberOfTables = this.selectedTables.filter(t => t.name.startsWith(this.selectedTable.name))
      this.tableNameSuffix = numberOfTables.length + 1
      const tbl = {
        color: table.color,
        id: uuidv4(),
        locked: false,
        locked_by: state.getUser().id,
        number: this.getCurrentTableNumber() + 1,
        name: `${sourceTable.name}/${this.tableNameSuffix}`,
        persistent: false,
        items: table.items,
        user_id: this.userId,
        user_name: this.user.name + ' ' + this.user.surname,
        update_time: Date.now()
      }
      const docRef = df.collection(`location_orders/${state.getPointOfSale().id}/tables`)

      try {
        await docRef.doc(tbl.id).set(tbl)
        console.info('Table successfully saved.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },
    getCurrentTableNumber () {
      if (this.availableTables.length === 0) {
        return 0
      }
      return Math.max(...this.availableTables.map(table => table.number))
    },

    async saveTable (table, persist, newTable = false) {
      let tblId, persistent, items
      if (table.id && table.id === this.selectedTable.id) {
        tblId = table.id
      } else {
        tblId = uuidv4()
        table.id = tblId
      }
      if (persist !== undefined) { persistent = persist } else { persistent = this.selectedTable.persistent }
      if (table.items.length > 0) { items = [...table.items] } else { items = [] }
      const docRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${tblId}`)

      var receiptNote = ''
      if (newTable === false) {
        receiptNote = clone(this.receiptNote)
        this.receiptNote = null
      }

      var newItems = []
      items.forEach(item => {
        if (item instanceof WriteInProductModel) {
          var price = item.prices.EUR.price

          var newItem = {
            active: item.active,
            barcode: item.barcode,
            categories: item.categories,
            category_ids: item.category_ids,
            code: item.code,
            created: item.created,
            description: item.description,
            id: item.id,
            manual_price_input: item.manual_price_input,
            discountedUnitPrice: price,
            fullTotal: price,
            total: price,
            unitPrice: price,
            name: item.name,
            menu: item.menu,
            picture: item.picture,
            prices: item.prices,
            quantity: item.quantity,
            selling_unit: item.selling_unit,
            sold_count: item.sold_count,
            status: item.status,
            stock: item.stock,
            storage_unit: item.storage_unit,
            suppliers: item.suppliers,
            type: item.type
          }

          // TODO maknuti ako ne bude trebalo
          // var unitID = 'unit_1'
          // newItem.warehouse_units = {
          //   base_unit: unitID,
          //   default_sale: unitID,
          //   default_store: unitID,
          //   units: {
          //     unit_1: {
          //       id: unitID,
          //       base_unit: true,
          //       default_sale: true,
          //       default_store: true,
          //       is_selling_item: true,
          //       prices: {
          //         EUR: {
          //           active: true,
          //           base: 0,
          //           price: item.prices.EUR.price,
          //           taxes: item.prices.EUR.taxes

          //         }
          //       },
          //       storage_amount: 1000,
          //       storage_unit: item.selling_unit
          //     }
          //   }
          // }
          newItems.push(newItem)
        } else {
          newItems.push(item)
        }
      })
      var params = {
        id: tblId,
        name: table.name,
        number: table.number,
        items: newItems,
        persistent: persistent,
        color: table.color ? table.color : '',
        locked: table.locked ? table.locked : false,
        locked_by: state.getUser().id,
        orders: table.orders && table.orders.length > 0 ? table.orders : [],
        user_id: this.userId,
        user_name: this.user.name + ' ' + this.user.surname,
        update_time: Date.now(),
        receiptNote: receiptNote || ''
      }

      if (this.data.discount) {
        params.discount = this.data.discount
      }
      try {
        await docRef.set(
          params
        )
        console.info('Table successfully saved.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },
    prepareLogCallDelete (table) {
      var req = new LogData()

      let userId = auth.currentUser.uid
      if (state.getCashRegisterUser().id && state.getCashRegisterUser().id !== '') {
        userId = state.getCashRegisterUser().id
      }

      req.action_name = 'Brisanje stola'
      req.action_id = '3'
      req.user_id = userId

      var description = table.name
      description += '\n'

      var totalTable = 0
      if (table.items.length > 0) {
        table.items.forEach((item, k) => {
          if (k > 0 && k < table.items.length) {
            description += '\n'
          }

          var price = item.total
          totalTable += item.total

          var formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
          })

          var priceFormatted = formatter.format(price / 100)
          var amt = item.quantity
          description += amt.toFixed(2).replace('.', ',') + ' x ' + item.name + ' ' + priceFormatted
        })

        description += '\n'
      }

      var totalFormatted = (totalTable / 100).toFixed(2).replace('.', ',')
      description += 'Ukupan iznos stola: ' + totalFormatted + '€'
      req.description = description
      req.user_id = userId

      this.setLog(req)
    },

    async emptyTable (table) {
      if (state.getCurrentCompany().save_log) {
        this.prepareLogCallDelete(table)
      }

      var deleteTable = clone(table)
      var id = uuidv4()
      var time = new Date()
      deleteTable.delete_time = time.getTime()
      deleteTable.delete_time_readable = 'Datum: ' + ('0' + time.getDate()).slice(-2) + '.' + ('0' + (time.getMonth() + 1)).slice(-2) + '.' + time.getFullYear() + ', ' + 'Vrijeme: ' + ('0' + time.getHours()).slice(-2) + ':' + ('0' + time.getMinutes()).slice(-2)
      deleteTable.history_id = id
      var docRef = df.doc(`location_orders_history/${state.getPointOfSale().id}/tables/${id}`)

      try {
        await docRef.set(deleteTable)
        console.info('Document successfully saved.')
      } catch (err) {
        console.error('Error saving document: ', err)
      }

      docRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`)
      try {
        await docRef.set(
          {
            id: table.id,
            name: table.name,
            number: table.number,
            items: [],
            persistent: table.persistent,
            color: 'primary',
            locked: false,
            locked_by: '',
            mark_for_delete: true,
            update_time: 0,
            user_id: '',
            user_name: ''
          }
        )
        console.info('Table successfully emptied.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }
    },

    onDeleteTable (table) {
      this.deleteTable(table)
    },

    async deleteTable (table) {
      var docRef = df.doc(`location_orders_history/${state.getPointOfSale().id}/tables/${table.id}/history/${new Date().getTime()}`)
      var tableRef = df.doc(`location_orders_history/${state.getPointOfSale().id}/tables/${table.id}`)

      try {
        await tableRef.set({ id: table.id })
        await docRef.set(table)
        console.info('Document successfully saved.')
      } catch (err) {
        console.error('Error saving document: ', err)
      }

      docRef = df.doc(`location_orders/${state.getPointOfSale().id}/tables/${table.id}`)
      try {
        await docRef.delete()
        console.info('Document successfully deleted.')
        if (table.id === this.selectedTable.id) {
          this.selectedTable = new Table()
        }
      } catch (err) {
        console.error('Error deleteing document: ', err)
      }
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    setDefaults () {
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.maxIssueDate = toISOLocal(new Date())
      this.issueDate = ''
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },
    insertAccountDetails (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      if (receiptType === 'R') {
        if (paymentCard && paymentCard.name && paymentCard.name !== '') {
          this.selectedCard = paymentCard
        }
        this.paymentMethod = method
        this.r1DialogOpen = true
      }
    },
    onAccountDetailsFinishPayment (data) {
      this.type = data.type

      var selectedCard = this.selectedCard
      var selectedTerminal = this.selectedTerminal
      this.finishPayment(this.paymentMethod, 'R', undefined, selectedCard, selectedTerminal)
    },

    /// FINISH PAYMENT
    async finishPayment (method, receiptType, deliveryName = undefined, paymentCard = undefined, terminal = undefined) {
      if ((state.getCashRegister().type === 'MP' || state.getCashRegister().type === 'ALL') && (method === 'T' || method === 'V' || method === 'P') && this.data.discount && this.data.discount.amount > 0) {
        var type = 'Transakcijski račun'
        if (method === 'V') {
          type = 'Virman'
        }
        if (method === 'P') {
          type = 'Ponuda'
        }

        this.showMsgBox({
          title: type,
          text: type + ' se ne može izdati sa popustom na račun. Ukoliko želite nastaviti zamijenite popust na iznos sa popustom na artikle.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })

        return
      }
      if (this.selectedTable === undefined || this.selectedTable.id === '') {
        this.imidiatePrintPrepare(this.data.items)
      }

      var currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        currency = 'EUR'
      }

      if (receiptType === 'REPREZENTACIJA') {
        var reprezentacijaAmount = 200
        if (Date.now() / 1000 > 1672527599) {
          reprezentacijaAmount = 50
        }
        this.showLoader(this.$options.filters.money(reprezentacijaAmount, 100, currency), { opacity: 1 })
      } else {
        this.showLoader(this.$options.filters.money(this.data.total, 100, currency), { opacity: 1 })
      }

      let entity = 'receipt'

      if (method === 'V') {
        entity = 'virman'
      }

      var flag = 'BO'
      var fiscalize = true
      var offer = false
      if (method === 'P') {
        flag = 'P'
        entity = 'offer'
        offer = true
      }

      try {
        const items = await remapItems(this.data, method)
        const paymentMethod = getPaymentMethod(method)

        switch (paymentMethod) {
          case 'NOVČANICE':
            fiscalize = true
            break
          case 'KARTICA':
            fiscalize = true
            break
          case 'OSTALO':
            fiscalize = true
            break
          case 'TRANSAKCIJSKI RAČUN':
            fiscalize = false
            break
        }

        const args = {
          entity: entity,
          paymentMethod: paymentMethod,
          paymentReference: '',
          language: this.language,
          fiscalize: fiscalize,
          type: { flag: flag }
        }

        if (this.type) {
          args.type = clone(this.type)
          this.type = ''
        }

        try {
          args.receiptNumber = await this.getCashRegisterNumber(receiptType, offer)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        let payload = await getPayload(this.data, items, args, state, this.receiptNote)
        if (entity === 'virman') {
          payload = await getVirmanPayload(this.data, items, args, state, this.receiptNote)
        }

        if (terminal) {
          payload.terminal = terminal
        }

        const httpArgs = {
          issueDateTime: this.dateTimeToUnixTimestamp(new Date()),
          paymentMeth: paymentMethod,
          language: this.language,
          type: this.type,
          openPdfPrint: this.openPdfPrint,
          hideLoader: this.hideLoader,
          removeOrderFromTable: this.removeOrderFromTable,
          fiscalize: fiscalize
        }

        try {
          httpArgs.receiptNumber = await this.getCashRegisterNumber(receiptType, offer)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        if (receiptType && receiptType !== '') {
          switch (receiptType) {
            case 'REPREZENTACIJA':
              httpArgs.reprezentacija = true
              break
            case 'DELIVERY':
              httpArgs.delivery = true
              httpArgs.deliveryName = deliveryName
              break
          }
        }

        if (paymentCard && paymentCard.type && paymentCard.type !== '') {
          httpArgs.paymentCard = paymentCard.type
        }

        if (paymentCard && paymentCard.name && paymentCard.name !== '') {
          httpArgs.paymentCard = paymentCard.name
        }

        httpArgs.msgBox = this.showMsgBox
        httpArgs.confirm = this.confirm
        httpArgs.confirmClose = this.confirmClose

        const additionalData = {
          callNewPrint: true
        }

        if (entity === 'virman') {
          sendVirmanHttp(this.data, items, payload, httpArgs, this.selectedPrinter, additionalData)
        } else {
          sendHttp(this.data, items, payload, httpArgs, this.selectedPrinter, additionalData)
        }

        this.receiptNote = null
        this.setDefaults()
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },

    async getCashRegisterNumber (receiptType, offer = false) {
      var cashRegister = await retry(this.getCashRegister, 3)

      if (!cashRegister) {
        return
      }

      if (offer === true) {
        return cashRegister.offer_sequence + 1
      } else {
        return cashRegister.bill_sequence + 1
      }
    },

    async getCashRegister () {
      const response = await df.doc(`cash_registers/${state.getCashRegister().id}`).get()
      if (!response.data()) {
        throw new Error('Nije moguće dohvatiti podatke o blagajni')
      } else {
        return response.data()
      }
    },

    setupTableContainer () {
      const ele = document.getElementById('container')
      if (ele) {
        let pos = { top: 0, left: 0, x: 0, y: 0 }
        const mouseDownHandler = function (e) {
          ele.style.cursor = 'grabbing'
          ele.style.userSelect = 'none'
          pos = {
            // The current scroll
            left: ele.scrollLeft,
            top: ele.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY
          }
          const mouseMoveHandler = function (e) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x
            const dy = e.clientY - pos.y

            // Scroll the element
            ele.scrollTop = pos.top - dy
            ele.scrollLeft = pos.left - dx
          }
          const mouseUpHandler = function () {
            document.removeEventListener('pointermove', mouseMoveHandler)
            document.removeEventListener('pointerup', mouseUpHandler)

            ele.style.cursor = 'grab'
            ele.style.removeProperty('user-select')
          }

          document.addEventListener('pointermove', mouseMoveHandler)
          document.addEventListener('pointerup', mouseUpHandler)
        }

        document.addEventListener('pointerdown', mouseDownHandler)
      }
    },
    resetTables () {
      const interval = setInterval(() => {
        if (this.selectedTables.length > 0) {
          clearInterval(interval)
          if (this.selectedTable.number === 0) {
            this.selectedTables.forEach((t) => {
              if (t.locked && t.locked_by === state.getUser().id) {
                df.doc(`location_orders/${state.getPointOfSale().id}/tables/${t.id}`).update({ color: 'primary', locked: false })
                  .catch((err) => {
                    console.error(err)
                    this.showMsgBox({ text: `Pogreška prilikom resetiranja: ${err}`, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
                  })
              }
            })
          }
        }
      }, 100)
    },
    getCards () {
      const that = this

      df
        .doc(`company_cards/${state.getCurrentCompany().id}`)
        .onSnapshot((doc) => {
          if (
            doc &&
             doc.data() &&
             doc.data().cards &&
           doc.data().cards.length > 0
          ) {
            that.availableCards = doc.data().cards.filter(card => card.active === true)
          }
          that.availableCards = that.availableCards.sort((a, b) => a.name.localeCompare(b.name))
        })
    },
    onAccountDetailsPreviewReceipt (data) {
      this.showPreview = false
      this.type = data.type
      this.type.flag = this.previewReceiptType
      this.previewReceipt(this.paymentMethod, this.previewReceiptType)
    },
    insertPreviewAccountDetails (method, receiptType) {
      if (receiptType === 'R' || receiptType === 'P') {
        this.previewReceiptType = receiptType
        this.paymentMethod = method
        this.showPreview = true
        this.r1DialogOpen = true
      }
    },
    changeWindowWidth () {
      if (window.innerWidth <= 1100) {
        this.smallWindow = true
      } else {
        this.smallWindow = false
      }
    },
    async previewReceipt (method, receiptType) {
      if ((state.getCashRegister().type === 'MP' || state.getCashRegister().type === 'ALL') && (method === 'T' || method === 'V' || method === 'P') && this.data.discount && this.data.discount.amount > 0) {
        var type = 'Transakcijski račun'
        if (method === 'V') {
          type = 'Virman'
        }

        if (method === 'P') {
          type = 'Ponuda'
        }

        this.showMsgBox({
          title: type,
          text: type + ' se ne može izdati sa popustom na račun. Ukoliko želite nastaviti zamijenite popust na iznos sa popustom na artikle.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })

        return
      }

      if (!this.data.items || this.data.items.length === 0) {
        this.showMsgBox({
          text: 'Trenutna košarica ne sadrži artikle, molimo dodajte artikle kako bi pokrenuli pregled računa.',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })

        return
      }
      var deliveryName

      var data = clone(this.data)

      var currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        currency = 'EUR'
      }

      if (receiptType === 'REPREZENTACIJA') {
        var reprezentacijaAmount = 200
        if (Date.now() / 1000 > 1672527599) {
          reprezentacijaAmount = 50
        }
        this.showLoader(this.$options.filters.money(reprezentacijaAmount, 100, currency), { opacity: 1 })
      } else {
        this.showLoader(this.$options.filters.money(data.total, 100, currency), { opacity: 1 })
      }

      var flag = 'BO'
      let entity = 'receipt_preview'
      var offer = false
      if (method === 'V') {
        flag = 'V'
        method = 'T'
        entity = 'virman_preview'
      }
      if (receiptType === 'P') {
        flag = 'P'
        entity = 'offer_preview'
        offer = true
      }

      const fiscalize = true

      try {
        const items = await remapItems(data)
        const paymentMethod = getPaymentMethod(method)

        const args = {
          entity: entity,
          paymentMethod: paymentMethod,
          paymentReference: '',
          language: this.language,
          fiscalize: fiscalize,
          type: { flag: flag }
        }

        if (this.type) {
          args.type = clone(this.type)
          this.type = ''
        }

        try {
          args.receiptNumber = await this.getCashRegisterNumber(receiptType, offer)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        let payload = await getPayload(data, items, args, state, this.receiptNote)
        if (entity === 'virman_preview') {
          payload = await getVirmanPayload(data, items, args, state, this.receiptNote)
        }

        const httpArgs = {
          issueDateTime: this.dateTimeToUnixTimestamp(new Date()),
          paymentMeth: paymentMethod,
          language: this.language,
          type: this.type,
          openPdfPrint: this.openPdfPrint,
          hideLoader: this.hideLoader
        }

        try {
          httpArgs.receiptNumber = await this.getCashRegisterNumber(receiptType)
        } catch (err) {
          this.hideLoader()
          EventBus.$emit('showError', { message: err })
          return
        }

        if (receiptType && receiptType !== '') {
          switch (receiptType) {
            case 'REPREZENTACIJA':
              httpArgs.reprezentacija = true
              break
            case 'DELIVERY':
              httpArgs.delivery = true
              httpArgs.deliveryName = deliveryName
              break
          }
        }

        httpArgs.msgBox = this.showMsgBox

        sendPreview(data, items, payload, httpArgs, this.selectedPrinter)
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    getWholesaleItem (item) {
      const newItem = clone(item)
      var pnpAmt = 0
      if (newItem.pnp && newItem.pnp !== null && newItem.pnp.rate && newItem.pnp.rate > 0) {
        pnpAmt = newItem.pnp.rate
      }

      var taxes = newItem.pdv.rate + pnpAmt

      newItem.price =
    Math.round(parseFloat(newItem.price / (1 + taxes / 10000)), 2)

      newItem.single_item_price =
    Math.round(parseFloat(newItem.single_item_price / (1 + taxes / 10000)), 2)

      return newItem
    }

  },

  created () {
    this.user = state.getCashRegisterUser().id ? state.getCashRegisterUser() : state.getUser()
    this.userId = this.user.id
    this.manager = !!(this.user.roles && this.user.roles.VODITELJ && this.user.roles.VODITELJ === true)
    this.selectedTable = new Table()
    this.getTables()
    this.resetTables()
    this.tooltipDelay = state.getTooltipDelay()
  },

  mounted () {
    this.changeWindowWidth()
    window.addEventListener('resize', this.changeWindowWidth)
    this.getCards()
    this.data.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.data.currency = 'EUR'
    }

    this.selectedCard = undefined
    this.setupTableContainer()

    // if (state.getPrinterLib() === 'BIXOLON80') {
    //   const issueID = 1
    //   printerInit(issueID)
    //   state.setPosPrinterSettings(2)
    // } else if (state.getPrinter() === 'UNIVERSAL') {
    //   initPrintManager()
    // }

    // initPrintManager()

    EventBus.$off('preview_receipt')
    EventBus.$on('preview_receipt', (method, type) => {
      this.previewReceipt(method, type)
    })

    EventBus.$off('avans')
    EventBus.$on('avans', () => {
      this.$refs.r1ReceiptPaymentDialog.open()
    })

    EventBus.$off('preview_receipt_r')
    EventBus.$on('preview_receipt_r', (method, type) => {
      this.insertPreviewAccountDetails(method, type)
    })

    EventBus.$off('clear-reload-tables')
    EventBus.$on('clear-reload-tables', () => {
      this.selectedTables = []
      this.getTables()
      this.selectedTable = new Table()
    })

    EventBus.$off('clear-table')
    EventBus.$on('clear-table', () => {
      this.removeOrderFromTable()
    })

    // EventBus.$off('set-mode')
    EventBus.$on('set-mode', (editMode) => {
      this.editMode = editMode
    })

    EventBus.$off('set-orders-view')
    EventBus.$on('set-orders-view', (ordersView) => {
      this.ordersView = ordersView
    })

    EventBus.$off('save-receipt-note')
    EventBus.$on('save-receipt-note', (receiptNote) => {
      this.receiptNote = receiptNote
    })

    EventBus.$off('deliveryFinishSimplePayment')
    EventBus.$on('deliveryFinishSimplePayment', (deliveryService, paymentMethod) => {
      this.finishPayment(paymentMethod, 'DELIVERY', deliveryService, '')
    })

    this.getMultiplier()
    this.setDefaults()
    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            this.cashRegisterForTerminal = doc.data()
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )

    this.orgUnits = []
    let query = df.collection(`location_units/${state.getPointOfSale().id}/units`)
    let listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.orgUnits.push(rec.data())
      })
    })
    this.listeners.push(listener)

    this.printers = []
    query = df.collection(`location_printers/${state.getPointOfSale().id}/printers`)
    listener = query.onSnapshot((doc) => {
      doc.docs.forEach((rec) => {
        this.printers.push(rec.data())
      })
    })

    this.listeners.push(listener)
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  }
}
</script>

<style scoped>
#container {
  overflow-x: hidden;
  overflow-y: none;
  cursor: grab;
  touch-action: none;
  /* border: 1px solid#fd0000; */
  margin-top: 12px;
}

@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
    max-width: 180px;
  }
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
    max-width: 120px;
  }
}
.panel-cell {
  padding: 0 2px 2px 0;
}
.label {
  font-size: 0.6rem;
  color: white;
  min-width: 90px;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.disable-events {
  pointer-events: none;
  background-color: #e0e0e0;
}

.accent-btn {
  animation: glowing 700ms infinite;
  font-weight: 700;
}
.darkblue {
  background-color: #2c5a90;
}
.btn-lg {
  font-size: 1rem;
  font-weight: 700;
}
.btn-md {
  font-size: 0.8rem;
  font-weight: 700;
}
.btn-sm {
  font-size: 0.7rem;
  font-weight: 700;
}
.btn-purple {
  color: white;
  background-color: #b10090;
}
.notification-green {
  font-size: 12px;
  background-color: #8ff4a2;
  font-weight: bold;
}
.notification-yellow {
  font-size: 12px;
  background-color: #edf68b;
  font-weight: bold;
}
.notification-red {
  font-size: 12px;
  background-color: #e898a0;
  color: white;
  font-weight: bold;
}
.notification-purple {
  font-size: 12px;
  background-color: #9c27b0;
  font-weight: bold;
}
</style>
