
<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="430"
    style="z-index: 6"
  >
    <v-form ref="addCategoryForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Popust</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="justify-center pt-2">
              <v-select
                outlined
                label="Odaberi popust"
                v-model="selectedDiscount"
                item-text="name"
                return-object
                :items="discounts"
                :rules="[rules.req]"
              >
              </v-select>
            </v-row>
            <v-row
              class="justify-center pt-5"
              v-if="
                selectedDiscount !== undefined &&
                selectedDiscount.type === 'MANUAL'
              "
            >
              <v-col cols="8" class="pa-0 justify-end">
                <v-row>
                  <v-col cols="8">
                    <quantity-input
                      dense
                      outlined
                      label="Iznos"
                      v-model="amt"
                      :rules="[rules.req]"
                    ></quantity-input>
                  </v-col>
                  <v-switch
                    cols="4"
                    style
                    v-model="switchType"
                    inset
                    :label="switchType ? 'EUR' : '%'"
                  ></v-switch>
                  <!-- <v-btn-toggle v-model="selectedType" class="md-10">
                    <v-btn max-height="40">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn max-height="40"> {{ currency }} </v-btn>
                  </v-btn-toggle> -->
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj popust
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import QuantityInput from '../../../../components/QuantityInput.vue'
export default {
  components: { QuantityInput },
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    submitting: false,
    selectedDiscount: undefined,
    manualType: undefined,
    discounts: [],
    amt: undefined,
    amtValid: false,
    currency: undefined,
    itemID: undefined,
    basketDiscount: false,
    rules: {
      req: rules.req('Required field')
    },
    multiplier: 0,
    listeners: [],
    switchType: false
  }),
  computed: {
    selectedType () {
      if (this.switchType === false) {
        return 0
      } else {
        return 1
      }
    },
    discTypes () {
      return [
        { name: '%', value: 'RATE' },
        { name: this.currency, value: 'AMOUNT' }
      ]
    }

  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }

    this.getMultiplier()
    const query = df.collection(`companies/${state.getCurrentCompany().id}/discounts`).orderBy('name', 'asc')
    query.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const discount = doc.data()
        if (discount !== undefined && discount.users !== undefined && discount.users !== null && discount.users.length > 0 && discount.active === true) {
          discount.users.forEach(id => {
            if (id === state.getUser()?.id) {
              discount.name = this.capitalizeFirst(discount.name)
              this.discounts.push(discount)
            }
          })
        } else {
          if (discount.active === true) {
            discount.name = this.capitalizeFirst(discount.name)
            this.discounts.push(discount)
          }
        }
      })
    })

    this.discounts.sort((a, b) => a.name.localeCompare(b.name))
  },
  methods: {
    amountValid () {
      if (this.selectedType !== undefined) {
        switch (this.selectedType) {
          case 0: {
            if (this.amt >= 100 || this.amt <= 0) {
              this.submitting = false
              this.amtValid = false
            } else {
              this.submitting = true
              this.amtValid = true
            }
            break
          }
          case 1: {
            if (this.amt > 1) {
              this.submitting = true
              this.amtValid = true
            }
            break
          }
        }
      }
    },
    capitalizeFirst (name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    open (basketDiscount = false, itemID, currency) {
      this.currency = currency
      this.visible = true

      this.basketDiscount = basketDiscount
      if (itemID !== '') {
        this.itemID = itemID
      }
    },
    close () {
      this.$emit('close')
      this.visible = false
      this.selectedDiscount = undefined
      this.basketDiscount = false
      this.manualType = undefined
      this.submitting = false
      this.amtValid = false
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = e => {
        this.picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    submit () {
      if (this.selectedDiscount === undefined) return false

      // this.amountValid()
      // if (!this.amtValid) return false
      let discount = this.selectedDiscount
      if (this.selectedDiscount.type === 'MANUAL') {
        if (!this.amt) {
          return
        }
        this.submitting = true

        this.amt = parseFloat(this.amt.toString().replace(',', '.'))

        discount = {
          active: true,
          amount: this.amt,
          company_id: state.getCurrentCompany().id,
          id: uuidv4(),
          name: this.selectedDiscount.name,
          type: this.selectedType === 0 ? 'RATE' : 'AMOUNT',
          users: this.selectedDiscount.users
        }

        if (this.selectedType === 0) {
          discount.rate = this.amt * 100
        } else {
          discount.amount = this.amt * 100
        }
      }

      discount.basketDiscount = this.basketDiscount
      if (this.itemID !== undefined) {
        discount.itemID = this.itemID
      }

      this.$emit('add', { ...discount })
      this.submitting = false
      this.close()
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
