<template>
  <span
    ref="textf"
    :style="`height: 56px; font-size: 14px; display: grid; align-items: center; color: ${color}`"
    class="text-wrap label"
    >{{ label }}</span
  >
</template>
<script>
import textfit from 'textfit'
// import { clone } from '@/plugins/utils'

export default {
  name: 'PrioductTile',
  props: ['label', 'color'],
  watch: {
    label (nval, oval) {
      this.$refs.textf.textContent = nval
      if (nval !== '' && nval !== oval) {
        textfit(this.$refs.textf, { minFontSize: 5, maxFontSize: 14, alignVert: true, alignHoriz: true, multiLine: true, alignVertWithFlexbox: true })
      }
    }

  }
}
</script>
