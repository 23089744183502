<template>
  <v-dialog
    v-model="dialogOpen"
    v-if="dialogOpen"
    content-class="my-custom-dialog"
    persistent
    max-width="60%"
    @keydown.esc="close"
    scrollable
  >
    <v-card tile color="white">
      <v-card-title>
        <h3 style="text-transform: uppercase" class="text--secondary">
          {{ this.selectedCategoryName }}
        </h3>
        <v-spacer></v-spacer>
        <v-icon large @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="white">
        <v-container class="panel-container">
          <v-row class="panel-row">
            <v-col
              v-for="(item, idxc) in sortedProducts"
              :key="idxc"
              :cols="4"
              class="panel-cell"
              align="center"
            >
              <v-btn
                block
                tile
                depressed
                min-height="6rem"
                :color="item.color"
                class=""
                return-object
                @click="onCellClick(item)"
                style="display: inline-block; border-radius: 12px"
              >
                <span class="white--text tile">{{ item.label }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="white"> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import { clone } from '@/plugins/utils'

export default {
  data () {
    return {
      dialogOpen: false,
      products: [],
      sortedProducts: [],
      cols: [],
      currency: 'EUR'
    }
  },
  props: {
    selectProductDialogOpen: {
      type: Boolean,
      default: false
    },
    selectedCategoryId: {
      type: String,
      default: ''
    },
    selectedCategoryName: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
  },
  watch: {
    dialogOpen: {
      handler (nv) {
        this.cols = []
        this.sortedProducts = []
      },
      immediate: true
    },
    selectProductDialogOpen (newValue, _) {
      this.dialogOpen = newValue
      if (this.selectedCategoryId !== '') {
        this.cols = []
        this.getProductsByCategory(this.selectedCategoryId)
      }
    }
  },
  methods: {
    onCellClick (item) {
      this.$emit('addProduct', item)
    },

    close () {
      this.$emit('addItemDialogClose')
    },

    async getProductsByCategory (categoryId) {
    //  this.sortedProducts = []
      if (categoryId === '5692439e-4269-4fd9-875e-71e0e08a4e99') {
        const query = await df.collection(`location_menu_pricelist/${state.getPointOfSale().id}/items`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
        // .where(`prices.${this.currency}.active`, '==', true)
          .get()

        const documents = query.docs.map(doc => doc.data())
        documents.forEach(product => {
          this.cols.push({ type: 'menu-product', color: '#2C5A90', id: product.id, label: product.name })
        })
      } else {
        const query = await df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
          .where('category_ids', 'array-contains', `${categoryId}`)
          .where('status', '==', 'OK')
          .where('active', '==', true)
        // .where(`prices.${this.currency}.active`, '==', true)
          .get()

        const documents = query.docs.map(doc => doc.data())
        documents.forEach(product => {
          if (product.warehouse_units && product.warehouse_units.units) {
            Object.keys(product.warehouse_units.units).forEach(key => {
              if (product.warehouse_units.units[key].prices && product.warehouse_units.units[key].prices[this.currency].active === true) {
                let newProduct = {}
                newProduct = clone(product)
                newProduct.prices = product.warehouse_units.units[key].prices

                var stUnit = product.warehouse_units.units[key].storage_unit
                if (stUnit === '.kom') {
                  stUnit = ''
                }
                if (product.warehouse_units.units[key].base_unit === true) {
                  stUnit = product.selling_unit
                }
                newProduct.name = product.name + ' ' + stUnit
                // if (product.warehouse_units.units[key].storage_unit && product.warehouse_units.units[key].storage_unit !== '') {
                //   newProduct.name = product.warehouse_units.units[key].storage_unit
                // } else {
                //   newProduct.name = product.name
                // }
                newProduct.id = newProduct.id + '.' + product.warehouse_units.units[key].id
                this.cols.push({ type: 'product', color: '#2C5A90', id: newProduct.id, label: newProduct.name })
              }
            })
          } else {
            this.cols.push({ type: 'product', color: '#2C5A90', id: product.id, label: product.name })
          }
        })
      }
      this.sortedProducts = clone(this.cols.sort((a, b) => a.label.localeCompare(b.label)))
    }
  }
}
</script>

<style scoped>
.v-dialog__content >>> .my-custom-dialog {
  position: absolute;
  top: 30px;
  border-radius: 25px;
}
.panel-container {
  margin-top: 1rem;
}
.text-wrap {
  width: min-content;
  margin: auto;
}
.panel-cell {
  padding: 1px;
}
.active {
  background-color: var(--v-accent-darken3);
  color: white;
}
.label {
  font-size: 0.6rem;
  color: white;
}
span.tile {
  display: block;
  white-space: normal;
  max-width: 320px;
  font-weight: 700;
  font-size: 1.4rem;
}
</style>
