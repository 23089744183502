<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-if="dialog"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      @keydown.esc="close"
      scrollable
      width="30%"
      height="20%"
    >
      <v-card
        tile
        color="white"
        class="pb-3"
        style="border-radius: 25px !important"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;
            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold">Izmjene</span>
        </v-card-title>

        <v-card-text class="white pr-2 pl-2">
          <v-container>
            <v-row class="panel-row">
              <v-tabs
                class="pb-2"
                grow
                v-model="activeTab"
                @change="changeActiveTab"
              >
                <v-tab v-for="tab of tabs" :key="tab.id">
                  <v-icon class="pr-2">{{ tab.icon }}</v-icon>
                  <span
                    ><b>{{ tab.name }}</b></span
                  >
                </v-tab>
              </v-tabs>
              <div style="width: 100%" v-if="activeTab == 0">
                <v-col cols="12" class="pl-2 pr-2 pt-0">
                  <table class="tablica" style="width: 100%">
                    <tr>
                      <td
                        colspan="4"
                        style="
                          margin-bottom: 20px !important;
                          padding: 20px 0px 20px 0px;
                          text-align: center;
                        "
                      >
                        <span
                          style="
                            font-size: 30px;
                            font-weight: bold;
                            text-transform: uppercase;
                          "
                          >{{ item.name }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <!-- <td style="width: 100%">
                        <span style="font-size: 15px">Količina:</span>
                      </td> -->
                      <td
                        colspan="4"
                        style="
                          text-align: center;
                          width: 100%;
                          padding-bottom: 20px;
                        "
                      >
                        <v-text-field
                          dense
                          class="pb-5 kolicina"
                          height="4rem"
                          style="
                            width: 120px;
                            font-size: 30px;
                            font-weight: bold;
                            color: #757575;
                            text-align: center;
                            align-items: center;
                            margin: auto;
                            display: block;
                            line-height: 50px !important;
                          "
                          outlined
                          hide-details
                          v-model="item.quantity"
                          @change="callRecalculate()"
                        ></v-text-field>
                        <v-btn
                          style="border: solid 1px #9e9e9e; margin-right: 50px"
                          fab
                          color="green"
                          icon
                          x-large
                          @click="menuClick('increment')"
                        >
                          <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                          style="border: solid 1px #9e9e9e"
                          fab
                          x-large
                          icon
                          color="red"
                          @click="menuClick('decrement')"
                        >
                          <v-icon dark>mdi-minus</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td colspan="2" style="text-align: right">
                        <span style="font-size: 30px">Ukupno: </span>
                        <span style="font-weight: bold; font-size: 30px">{{
                          $options.filters.money(total, 100, currency)
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </div>
              <div style="width: 100%" v-if="activeTab != 0">
                <v-col
                  v-for="(option, idxc) in activeOptions"
                  :key="idxc"
                  :cols="12"
                  class="mb-1 pb-0 mt-0 pt-0"
                >
                  <v-btn
                    block
                    tile
                    depressed
                    min-height="6rem"
                    color="#295586"
                    class="mb-0 pb-0"
                    return-object
                    @click="menuClick(option.method)"
                  >
                    <span class="white--text tile btn-lg text-xs-center">{{
                      option.text
                    }}</span>
                  </v-btn>
                </v-col>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex pt-0 mt-0 pr-9 justify-end">
          <v-btn
            height="60px"
            width="80px"
            style="font-size: "
            id="success"
            class="btn successButton"
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      item: {},
      menu: [],
      card: null,
      cards: [
        { name: 'Maestro', value: 'maestro' },
        { name: 'MasterCard', value: 'mastercard' },
        { name: 'Visa', value: 'visa' }
      ],
      width: '500px',
      tabs: [],
      activeTab: 0,
      tabOptions1: [{ text: '+', icon: 'mdi-plus', method: 'increment' },
        { text: '-', icon: 'mdi-minus', method: 'decrement' },
        { text: 'Količina', icon: '', method: 'changeItemQuantity' }
      ],
      tabOptions2: [],
      currency: 'EUR'
    }
  },
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  methods: {
    open (item) {
      this.tabs = [
        { id: 0, name: 'Stanje', icon: 'mdi-note-edit-outline' },
        { id: 1, name: 'Promijene', icon: 'mdi-tray-arrow-down' }
      ]

      this.item = item
      this.menu = this.item.menu
      this.activeOptions = []
      this.tabOptions2 = []
      this.reformatOptions(0)
      this.dialog = true
    },
    reformatOptions (defaultActiveTab) {
      this.menu.forEach(option => {
        switch (option.text) {
          case 'Dodaj popust':
            this.tabOptions2.push(option)
            break

          case 'Ukloni popust':
            this.tabOptions2.push(option)
            break

          case 'Dodaj napomenu':
            this.tabOptions2.push(option)
            break

          case 'Ukloni artikl':
            this.tabOptions2.push(option)
            break
        }
      })

      this.activeTab = defaultActiveTab
      switch (defaultActiveTab) {
        case 0:
          this.activeOptions = this.tabOptions1
          break
        case 1:
          this.activeOptions = this.tabOptions2
          break
      }
    },
    changeActiveTab () {
      switch (this.activeTab) {
        case 0:
          this.activeOptions = this.tabOptions1
          break
        case 1:
          this.activeOptions = this.tabOptions2
          break
      }
    },
    close () {
      this.dialog = false
    },
    menuClick (method) {
      var closeOnDecrement = false
      if (this.item.quantity <= 1 && method === 'decrement') {
        closeOnDecrement = true
      }

      this.$emit('itemMenuClick', method, this.item)
      if ((method !== 'increment' && method !== 'decrement')) {
        this.close()
      }

      setTimeout(() => {
        if (closeOnDecrement) {
          this.close()
        }
      }, 50)
    },
    callRecalculate () {
      this.$emit('recalculate')
    }
  }
}
</script>

<style scoped>
span.darkblue {
  color: #275281;
}

@media only screen and (max-height: 899px) {
  #container {
    max-height: 193px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}

.panel-row-inactive {
  background-color: #816827;
}

@media only screen and (min-height: 900px) {
  #container {
    max-height: 440px;
  }
  span.tile {
    display: block;
    white-space: normal;
  }
}
.btn-lg {
  font-size: 1.5rem;
  font-weight: 700;
}
/* .tablica {
  border: 1px solid black;
}

.tablica td {
  border: 1px solid black;
} */

.kolicina >>> input {
  text-align: center;
}
</style>
